
import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useLocation } from "react-router-dom"

import { TableComponent } from "../../../shared/components/table"
import ReactPaginate from 'react-paginate';

import { FilterUtil } from "../../../shared/components/report-filter"


import { appActions } from "../../../redux/actions/in-app"

import { appConstants } from "../../../redux/action-constants/inapp.constants";

import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"

import { getDateFromISO } from "../../../shared/utils"

import Modal from 'react-bootstrap/Modal'

import MasterCard from "../../../assets/logos_mastercard.svg";
import VisaCard from "../../../assets/visa.svg";

import "../index.scss"
let isDetailsVisible: boolean = false;

const getOrderDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getOrderDetailsAction(payload);

}
const convertTime = (time: any) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours


    }

    return time.join(''); // return adjusted time or original string
}

const getDateTxt = (iso: any) => {
    let dateTxt: any = new Date(`${iso}`);
    let timeInfo = dateTxt.getFullYear().toString();
    dateTxt = dateTxt.toString();
    dateTxt = dateTxt.split("GMT")[0].trim();
    let dateOnly = dateTxt.split(timeInfo)[0]


    timeInfo = dateTxt.split(timeInfo)[1].trim();
    let test1 = `${timeInfo}`

    timeInfo = convertTime(test1);


    return `${dateOnly} ${timeInfo}`;
}

const OrderDetails = (pageProps: any) => {
    let fullOrderDetails = pageProps.getOrderDetailsRequest?.request_data?.response?.data;


    return (
        <div className="orderdetails_wrap">

        </div>
    )
}
const OrderDetailsModal = (props: any) => {
    const [showCurrentModal, setShowCurrentModal] = useState<boolean>(true);
    let { showOrderDetailsModal, setShowOrderDetailsModal, minorOrderInfo, pageProps } = props;
    let orderInfo = pageProps.getOrderDetailsRequest?.request_data?.response?.data

    // useEffect(() => {
    //     if (hideCurrentModal) {
    //         getOrderDetails({
    //             pageProps,
    //             payload: "CLEAR"
    //         }).then(() => {

    //         })
    //     }


    // }, [hideCurrentModal])




    const hideModal = () => {
        getOrderDetails({ pageProps, payload: "CLEAR" })
        setShowCurrentModal(false)
    }
    return (
        <Modal className="order_info_wrap" show={showCurrentModal} onHide={hideModal}>
            {pageProps.getOrderDetailsRequest.request_status === appConstants.GET_ORDER_DETAILS_SUCCESS &&
                <>
                    <Modal.Header closeButton>
                        <div className="order_num">Order #{orderInfo?.orderNumber}</div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="order_headings">
                            <div className="date_num">
                                <div className="order_top_txt">Order Date:
                                    <span>
                                        {(new Date(orderInfo?.orderDate).getDate()) < 10 ? `0${new Date(orderInfo?.orderDate).getDate()}` : new Date(orderInfo?.orderDate).getDate()}/
                                        {(new Date(orderInfo?.orderDate).getMonth()) < 10 ? `0${new Date(orderInfo?.orderDate).getMonth()}` : new Date(orderInfo?.orderDate).getMonth()}/
                                        {new Date(orderInfo?.orderDate).getFullYear()}
                                    </span>
                                </div>
                                {/* <div className="order_top_txt">Order Date: <span> {getDateTxt(minorOrderInfo?.createdDate)}</span> </div> */}
                                <div className="order_top_txt">Expected Delivery:

                                    <span>
                                        {(new Date(orderInfo?.deliveryDate).getDate()) < 10 ? `0${new Date(orderInfo?.deliveryDate).getDate()}` : new Date(orderInfo?.deliveryDate).getDate()}/
                                        {(new Date(orderInfo?.deliveryDate).getMonth()) < 10 ? `0${new Date(orderInfo?.deliveryDate).getMonth()}` : new Date(orderInfo?.deliveryDate).getMonth()}/
                                        {new Date(orderInfo?.deliveryDate).getFullYear()}
                                    </span>
                                </div>
                                <div className="order_top_txt">Number of Products: <span>{orderInfo?.numberOfProduct} </span> </div>
                            </div>
                        </div>
                        <div className="order_full_details">
                            <div className="order_items_list">
                                {
                                    orderInfo?.vendorOrderDetails.map((eachItem: any, index: any) => {
                                        return (
                                            <div key={index} className="order_store">
                                                <div className="store_info">
                                                    <div className="logo_n_name">
                                                        <div className="store_logo">
                                                            <img src={eachItem?.storeLogoUrl} className="store_logo_img" alt="" />
                                                        </div>
                                                        <div className="store_name">
                                                            {eachItem.storeName}
                                                            <div className="address_txt">{eachItem?.storeAddress}</div>
                                                        </div>
                                                    </div>
                                                    <div className="total_in_store">
                                                        ${eachItem?.amount}
                                                    </div>
                                                </div>
                                                <div className="all_products">
                                                    {
                                                        eachItem.products.map((eachProduct: any, productIdx: any) => {
                                                            return (
                                                                <div className="each_product" key={productIdx}>
                                                                    <div className="item_photo_name">
                                                                        <div className="item_photo">
                                                                            <img src={eachProduct.productUrl} alt="" />

                                                                        </div>
                                                                        <div className="item_name_cta">
                                                                            {eachProduct?.categories &&
                                                                                <div className="product_cats">
                                                                                    {eachProduct?.categories.map((eachCat: any, catIndex: any) => {

                                                                                        return (
                                                                                            <span key={catIndex} className="each_cat_name">{eachCat}{eachProduct?.categories.length - 1 > catIndex ? ", " : ""}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                            <div className="item_name_txt">{eachProduct.name}</div>
                                                                            <div className="item_qty_txt"> Quantity: {eachProduct.quantity}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="qty_price">
                                                                        <div className="qty_price_txt">${eachProduct?.subTotal}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="summary_wrap">
                                <div className="each_box_item">

                                    <div className="box_title">
                                        Order Summary
                                    </div>
                                    <div className="order_main_info">
                                        <div className="other_order_info">
                                            <div className="each_info">
                                                <div className="info_title">Subtotal</div>
                                                <div className="info_price">${orderInfo?.subTotal}</div>
                                            </div>
                                            <div className="each_info">
                                                <div className="info_title">Delivery Cost</div>
                                                <div className="info_price">${orderInfo?.deliveryFees}</div>
                                            </div>
                                            {orderInfo?.tipAmount > 0 &&
                                                <div className="each_info">
                                                    <div className="info_title">Tip</div>
                                                    <div className="info_price">${orderInfo?.tipAmount}</div>
                                                </div>
                                            }

                                            <div className="each_info">
                                                <div className="info_title">Service Fee</div>
                                                <div className="info_price"> ${orderInfo?.serviceFee}</div>
                                            </div>
                                            <div className="each_info">
                                                <div className="info_title">Tax</div>

                                                <div className="info_price"> ${orderInfo?.tax}</div>
                                            </div>

                                            <div className="each_info_main">
                                                <div className="info_title"> Total</div>
                                                <div className="info_price"> ${orderInfo?.totalSum}</div>
                                            </div>
                                            <div className="payment_mode">
                                                <div className="heading">Payment Method</div>
                                                <div className="payment_mode_info">
                                                    <div className="">

                                                        {orderInfo?.paymentMethodBrand === "mastercard" &&
                                                            <img className="card_brandicons" src={MasterCard} alt="" />
                                                        }
                                                        {orderInfo?.paymentMethodBrand === "visa" &&
                                                            <img src={VisaCard} className="card_brandicons" alt="" />
                                                        }

                                                    </div>
                                                    <div className="last_card_digits">
                                                        ****{orderInfo?.paymentCardLast4Digit}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box_title delivery_title">
                                        Delivery Information
                                    </div>

                                    <div className="box_title box_title_2">
                                        Delivery Type
                                    </div>
                                    <div className="other_order_info">
                                        <div className="each_info">
                                            <div className="info_title">{orderInfo?.isHomeDelivery ? "Home Delivered" : "Pick up"}</div>
                                        </div>
                                    </div>
                                    <div className="box_title box_title_2">
                                        Shipping Address
                                    </div>
                                    <div className="other_order_info">
                                        <div className="each_info">
                                            <div className="info_title">
                                                {orderInfo?.shippingAddress?.addressLine1 || "N/A"}<br />
                                                {orderInfo?.shippingAddress?.addressLine2}<br />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box_title box_title_2">
                                        Customer Name
                                    </div>
                                    <div className="other_order_info">
                                        <div className="each_info">
                                            <div className="info_title">
                                                {orderInfo?.customerName || "N/A"}<br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box_title box_title_2">
                                        Customer Email
                                    </div>
                                    <div className="other_order_info">
                                        <div className="each_info">
                                            <div className="info_title">
                                                {orderInfo?.customerEmail || "N/A"}<br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box_title box_title_2">
                                        Customer Phone
                                    </div>
                                    <div className="other_order_info">
                                        <div className="each_info">
                                            <div className="info_title">
                                                {orderInfo?.customerPhoneNumber || "N/A"}<br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            }

            {pageProps.getOrderDetailsRequest.request_status === appConstants.GET_ORDER_DETAILS_FAILURE &&
                <Modal.Body>
                    <AlertMsg type="error" message={pageProps.getOrderDetailsRequest?.request_data.error} />
                </Modal.Body>
            }
            {pageProps.getOrderDetailsRequest.request_status === appConstants.GET_ORDER_DETAILS_PENDING &&
                <Modal.Body>
                    <LoadingItem />
                </Modal.Body>
            }
        </Modal>
    )
}





const InAppContent = ({ pageProps }: any) => {
    const [showOrderDetailsModal, setShowOrderDetailsModal] = useState<boolean>(false);
    const [orderDetails, setOrderDetails] = useState<any>();
   

    let { orderData, 
        setPageNumber, 
        ordersList, 
        pageNumber, 
        pageSize, 
        setOrderstatus, 
        orderstatus,
        searchValue,
        setSearchValue,
        setSearchText } = pageProps

    


    const returnDeliveryStatus = (status: any) => {
        let orderStatusList: any[] = [
            {
                statusName: "Intent",
                orderStatus: 1
            },
            {
                statusName: "Processing",
                orderStatus: 2
            },
            {
                statusName: "Successful",
                orderStatus: 3
            },
            {
                statusName: "Failed",
                orderStatus: 4
            },
            {
                statusName: "Ready For Pickup",
                orderStatus: 5
            },
            {
                statusName: "Delivery Started",
                orderStatus: 6
            },
            {
                statusName: "Delivered",
                orderStatus: 6
            },
            {
                statusName: "Delivery Failed",
                orderStatus: 7
            }
        ]

        let orderStatus = orderStatusList.filter((each: any) => each?.orderStatus === status)[0]?.statusName;
        return orderStatus;
    }

    const ReportTable = () => {


        const getOrderInfo = (orderNum: any) => {
            getOrderDetails({
                pageProps,
                payload: `?Number=${orderNum}`
            }).then(() => { })
        }
        //     Delivery Status
        //     Intent = 1,
        // Processing,
        // Successful,
        // Failed,
        // ReadyForPickup,
        // DeliveryStarted,
        // Delivered,
        // DeliveryFailed
        return (
            <>
               {orderData && 
                <>
                    <thead>
                        <tr>
                            <th className="left_top"> <span >Order ID</span> </th>
                            {/* <th> Customer name</th> */}
                            <th>Order date</th>
                            <th>Delivery status</th>
                            <th>Total</th>
                            <th>Payment method</th>
                            <th className="right_top"> <span>Order information</span> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderData.map((eachOrder: any, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td className="grayed">#{eachOrder?.orderNumber}</td>
                                        {/* <td className="grayed">{eachOrder?.customerName||"N/A"}</td> */}
                                        <td className="grayed">{eachOrder.createdDate ? getDateFromISO({ date: eachOrder.createdDate, returnTime: true }) : "N/A"}</td>
                                        <td> <span className="order_status ongoing ">{returnDeliveryStatus(eachOrder?.deliveryStatus)}</span></td>
                                        <td>${eachOrder?.amount}</td>
                                        <td className="grayed">{eachOrder?.paymentMethod}</td>
                                        <td>
                                            <span className="branded see_cart" onClick={() => {
                                                // setOrderDetails(eachOrder)
                                                // setShowOrderDetailsModal(true)
                                                getOrderInfo(eachOrder?.orderNumber)
                                            }}>See cart</span>
                                            <span className="grayed">({eachOrder.noOfProducts})</span>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody >
                </>
                }
            </>
        )
    }


    const handlePageClick = (event: any) => {
        
        setPageNumber(event?.selected+1)
        
    }

    const ReportsDisplay = () => {
        return (

            <div className="reports_table">
                <div className="report_actions">
                    {/* {(orderDetails && showOrderDetailsModal) && */}
                    {pageProps.getOrderDetailsRequest.request_status !== appConstants.GET_ORDER_DETAILS_RESET &&

                        <OrderDetailsModal showOrderDetailsModal={isDetailsVisible}
                            setShowOrderDetailsModal={setShowOrderDetailsModal}
                            pageProps={pageProps} minorOrderInfo={orderDetails} setOrderDetails={setOrderDetails} />
                    }
                    <div className="order_filter">
                        <select name="" id="" value={orderstatus} onChange={(e:any)=>setOrderstatus(e.target.value)}>
                            <option value="">All</option>
                            <option value="1">Initiated</option>
                            <option value="2">Processing</option>
                            <option value="3">Successful</option>
                            <option value="4">Failed</option>
                            <option value="5">Ready For Pickup</option>
                            <option value="6">Delivery Started</option>
                            <option value="7">Delivered</option>
                            <option value="8">Delivery Failed</option>
                        </select>
                        
                        <input type="text" value={searchValue} autoFocus={true} className="search_table" placeholder="Search for order ID, customer name"
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                if(e.target.value===""){
                                    setSearchText("")
                                }
                                
                            }}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    
                                    if (e.target.value.length >= 2) {
                                        setSearchText(e.target.value.trim())
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="right_actions">
                        <FilterUtil />
                    </div>
                </div>
                <TableComponent
                    isEmptyRecords={!ordersList || ordersList?.results.length === 0}
                    childComponent={<ReportTable />}

                />
                {ordersList?.totalNumberOfRecords > pageSize &&
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        // initialPage={1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={4}
                        forcePage={pageNumber-1}
                        // onClick={handleItemsClick}
                        className="pagination_items"
                        pageClassName="page_num"
                        pageLinkClassName="page_link"
                        activeClassName="active_page_link"
                        previousClassName="previous_page_link"
                        nextClassName="next_page_link"
                        pageCount={ordersList?.pageNumber}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                    }
            </div>

        )
    }










    
    return (



        <ReportsDisplay />

    )
}

const OrdersListManagement = (pageProps: any) => {

    

    return (
        <InAppContent pageProps={pageProps} />
    )
}


const mapDispatchToProps = {
    getOrderDetailsAction: appActions.GetOrderDetails,
};

const mapStateToProps = (state: any) => ({
    getOrderDetailsRequest: state.allInAppReducers.getOrderDetailsReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(OrdersListManagement);